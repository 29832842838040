import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "../../../styles/admins/invoices/invoice.css";
import { useNavigate } from "react-router-dom";
import convertAmountToWords from "../../confs/amount_words";

const rowOne = { width: "7%", textAlign: "center" };
const rowTwo = { width: "68%" };
const rowThree = { borderRight: "none", width: "25%", textAlign: "right" };

function InvoicePage(props) {
  const contentRef = useRef();
  const navigate = useNavigate();
  const reactToPrintFn = useReactToPrint({
    contentRef,
    onAfterPrint: () => {
      setTimeout(() => {
        props.toggleHeader();
        if (props.isReprint) {
          navigate(-1);
        } else {
          localStorage.removeItem("rooms");
          localStorage.removeItem(
            `food_order_${props.invoiceDetails.booking_id}`
          );
          navigate("/admin/rooms", { replace: true });
        }
      }, 400);
    },
  });

  window.onpopstate = (e) => {
    props.toggleHeader();
  };

  const clientIs = localStorage.getItem("client_is");
  const clientDetails = JSON.parse(clientIs);

  const guests = JSON.parse(localStorage.getItem("guests"));

  const roomsType = JSON.parse(localStorage.getItem("rooms")).find(
    (r) => r.room_id === props.invoiceDetails.room_id
  );

  // Function to get guest details by guestId
  const getGuestDetails = (guestId) => {
    const guest = guests.find((g) => g.id === guestId);
    if (guest) {
      if (guest.is_com) {
        guest.name = JSON.parse(props.invoiceDetails.guest_data).name[0];
        var guestName = "";
        const Gname = guest.name.split(",");
        if (Gname.length > 1) {
          guestName = Gname[1];
          for (let i = 2; i < Gname.length; i++) {
            guestName = guestName + "," + Gname[i];
          }
        }
        return {
          name: Gname[0],
          guest_name: guestName,
          contact: guest.contact,
          comp_contact: guest.comp_contact ?? "",
          address: guest.address,
          is_com: guest.is_com,
        };
      } else {
        return {
          name: guest.name,
          contact: guest.contact,
          address: guest.address,
          is_com: guest.is_com,
        };
      }
    } else {
      return {
        name: JSON.parse(props.invoiceDetails.guest_data).name[0],
        contact: "",
      };
    }
  };

  const guestData = getGuestDetails(
    JSON.parse(props.invoiceDetails.guest_data).id[0]
  );

  const calculateDaysDifference = (from, to) => {
    if (!from || !to) return 0;
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const timeDifference = toDate - fromDate; // Difference in milliseconds
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
  };

  return (
    <section className="d-flex flex-column align-items-center">
      <div className="invoice-outer col-lg-6 col-md-10 col-12" ref={contentRef}>
        <div className="invoice-container">
          <div className="d-flex align-items-center">
            <img
              src={clientDetails.image}
              alt=""
              style={{ height: "86px", width: "86px", margin: "0px 10px" }}
            />
            <div className="invoice-header">
              <h2 style={{ fontSize: "30px" }}>
                {clientDetails.business_name.toUpperCase()}
              </h2>
              {clientDetails.tagline && (
                <p>{clientDetails.tagline.toUpperCase()}</p>
              )}
              <p>{clientDetails.address.toUpperCase()}</p>
              <p style={{ fontSize: "15.5px" }}>
                Contacts: {clientDetails.contact}
              </p>
              {clientDetails.email && (
                <p style={{ fontSize: "14px", marginBottom: "8px" }}>
                  {clientDetails.email}
                </p>
              )}
              <h2 style={{ fontSize: "18px" }}>TAX INVOICE</h2>
            </div>
          </div>

          <div className="invoice-details">
            <div className="registrations">
              {clientDetails.gstin !== null && (
                <p>GSTIN: {clientDetails.gstin}</p>
              )}
              {clientDetails.fssai !== null && clientDetails.fssai && (
                <p>FSSAI No.: {clientDetails.fssai}</p>
              )}
              {clientDetails.oyo_reg !== null && clientDetails.oyo_reg && (
                <p>OYO Reg No.: {clientDetails.oyo_reg}</p>
              )}
            </div>
            <div className="client-details">
              <div className="invoice-meta text-start">
                <p>
                  Company Name: <b>{guestData.is_com ? guestData.name : ""}</b>
                </p>
                <p>Company GST: {guestData.is_com ? guestData.contact : ""}</p>
                <p>
                  Guest Name:&nbsp;
                  <b>
                    {guestData.is_com ? guestData.guest_name : guestData.name}
                  </b>
                </p>
                <p>
                  Guest Contact:{" "}
                  {guestData.is_com
                    ? guestData.comp_contact
                    : guestData.contact}
                </p>
              </div>
              <div className="invoice-meta text-start">
                <p>Invoice No: INV{props.invoiceDetails.booking_id}</p>
                <p>Room No: {props.invoiceDetails.room_no}</p>
                <b>
                  <p>
                    Check In: {props.invoiceDetails.from_date.slice(8, 10)}-
                    {props.invoiceDetails.from_date.slice(5, 7)}-
                    {props.invoiceDetails.from_date.slice(0, 4)}{" "}
                    {props.invoiceDetails.from_date.slice(11, 16)}
                  </p>
                </b>
                <b>
                  <p>
                    Checkout: {props.invoiceDetails.to_date.slice(8, 10)}-
                    {props.invoiceDetails.to_date.slice(5, 7)}-
                    {props.invoiceDetails.to_date.slice(0, 4)}{" "}
                    {props.invoiceDetails.to_date.slice(11, 16)}
                  </p>
                </b>
              </div>
            </div>
            <div style={{ height: "48px" }}>
              <p className="address-line">Address: {guestData.address}</p>
            </div>
            <div
              style={{
                height: "380px",
                borderBottom: "1px solid #000000",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: `url(${clientDetails.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "270px",
                  opacity: 0.2, // Sets only the background opacity
                  zIndex: 0, // Ensure it's behind the content
                }}
              ></div>
              <table className="invoice-table">
                <thead>
                  <tr>
                    <th style={rowOne}>S.No.</th>
                    <th style={rowTwo}>Particulars</th>
                    <th style={rowThree}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={rowOne}>1</td>
                    <td style={rowTwo}>
                      Room
                      {roomsType ? ` (${roomsType.room_type})` : ""}
                    </td>
                    <td style={rowThree}>
                      ₹ {props.invoiceDetails.total_amount}
                    </td>
                  </tr>
                  {props.invoiceDetails.tax_amount > 0 && (
                    <tr>
                      <td style={rowOne}>2</td>
                      <td style={rowTwo}>
                        ROOM GST @{props.invoiceDetails.tax_rate}%
                      </td>
                      <td style={rowThree}>
                        ₹ {props.invoiceDetails.tax_amount}
                      </td>
                    </tr>
                  )}
                  {props.printFood &&
                    parseFloat(props.invoiceDetails.food_price) > 0 && (
                      <tr>
                        <td style={rowOne}>
                          {parseFloat(props.invoiceDetails.tax_amount) > 0
                            ? "3"
                            : "2"}
                        </td>
                        <td style={rowTwo}>Food Bill</td>
                        <td style={rowThree}>
                          ₹ {props.invoiceDetails.food_price}
                        </td>
                      </tr>
                    )}
                  {props.printFood &&
                    parseFloat(props.invoiceDetails.food_tax_amount) > 0 && (
                      <tr>
                        <td style={rowOne}>2</td>
                        <td style={rowTwo}>
                          Food GST @{props.invoiceDetails.food_tax_rate}%
                        </td>
                        <td style={rowThree}>
                          ₹ {props.invoiceDetails.food_tax_amount}
                        </td>
                      </tr>
                    )}
                  {props.printExtra && (
                    <tr>
                      <td style={rowOne}>
                        {props.invoiceDetails.tax_amount > 0
                          ? props.printFood
                            ? "4"
                            : "3"
                          : props.printFood
                          ? "3"
                          : "2"}
                      </td>
                      <td style={rowTwo}>Extra Charges</td>
                      <td style={rowThree}>
                        ₹ {props.invoiceDetails.extra_charges}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="total-details">
              <div className="invoice-total" style={{ textAlign: "left" }}>
                <p>
                  Total Day/Night of Stay :{" "}
                  {calculateDaysDifference(
                    props.invoiceDetails.from_date,
                    props.invoiceDetails.to_date
                  )}
                  {" X "}
                  {(
                    parseFloat(props.invoiceDetails.total_amount) /
                    calculateDaysDifference(
                      props.invoiceDetails.from_date,
                      props.invoiceDetails.to_date
                    )
                  ).toFixed(2)}
                </p>
                <p>
                  Total Number of Adults&nbsp;:{" "}
                  {props.invoiceDetails.no_of_stayers}
                </p>
                {parseFloat(props.invoiceDetails.advance_amount) > 0 && (
                  <p>
                    Advance Amount Paid&nbsp;&nbsp;: ₹{" "}
                    {props.invoiceDetails.advance_amount}
                  </p>
                )}
              </div>
              <div className="invoice-total">
                <p>
                  Gross Total:&nbsp;&nbsp; ₹&nbsp;
                  {(
                    parseFloat(props.invoiceDetails.total_amount) +
                    (props.printExtra
                      ? parseFloat(props.invoiceDetails.extra_charges)
                      : 0) +
                    (props.printFood
                      ? parseFloat(props.invoiceDetails.food_price)
                      : 0)
                  ).toFixed(2)}
                </p>
                {parseFloat(props.invoiceDetails.tax_amount) > 0 && (
                  <>
                    <p style={{ fontSize: "13px", fontWeight: "500" }}>
                      Room SGST@
                      {(parseFloat(props.invoiceDetails.tax_rate) / 2).toFixed(
                        2
                      )}
                      %: &nbsp;&nbsp;₹&nbsp;
                      {(
                        parseFloat(props.invoiceDetails.tax_amount) / 2
                      ).toFixed(2)}
                    </p>
                    <p style={{ fontSize: "13px", fontWeight: "500" }}>
                      Room CGST@
                      {(parseFloat(props.invoiceDetails.tax_rate) / 2).toFixed(
                        2
                      )}
                      %: &nbsp;&nbsp;₹&nbsp;
                      {(
                        parseFloat(props.invoiceDetails.tax_amount) / 2
                      ).toFixed(2)}
                    </p>
                  </>
                )}
                {props.printFood &&
                  parseFloat(props.invoiceDetails.food_tax_amount) > 0 && (
                    <>
                      <p style={{ fontSize: "13px", fontWeight: "500" }}>
                        Food SGST@
                        {(
                          parseFloat(props.invoiceDetails.food_tax_rate) / 2
                        ).toFixed(2)}
                        %: &nbsp;&nbsp;₹&nbsp;
                        {(
                          parseFloat(props.invoiceDetails.food_tax_amount) / 2
                        ).toFixed(2)}
                      </p>
                      <p style={{ fontSize: "13px", fontWeight: "500" }}>
                        Food CGST@
                        {(
                          parseFloat(props.invoiceDetails.food_tax_rate) / 2
                        ).toFixed(2)}
                        %: &nbsp;&nbsp;₹&nbsp;
                        {(
                          parseFloat(props.invoiceDetails.food_tax_amount) / 2
                        ).toFixed(2)}
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div
              style={{ height: "25px", justifyContent: "space-between" }}
              className="invoice-total d-flex"
            >
              <p>Payment Method: {props.invoiceDetails.payment_method}</p>
              <p>
                Grand Total:&nbsp;&nbsp; ₹&nbsp;
                {(
                  parseFloat(props.invoiceDetails.total_amount) +
                  (props.printExtra
                    ? parseFloat(props.invoiceDetails.extra_charges)
                    : 0) +
                  (props.printFood
                    ? parseFloat(props.invoiceDetails.food_price)
                    : 0) +
                  (props.printFood
                    ? parseFloat(props.invoiceDetails.food_tax_amount)
                    : 0) +
                  parseFloat(props.invoiceDetails.tax_amount)
                ).toFixed(2)}
              </p>
            </div>
            <p
              style={{
                fontWeight: "500",
                fontSize: "14px",
                margin: "0",
                padding: "0 5px",
                borderBottom: "1px solid #000000",
              }}
            >
              Amount in words:{" "}
              {convertAmountToWords(
                parseFloat(props.invoiceDetails.total_amount) +
                  (props.printExtra
                    ? parseFloat(props.invoiceDetails.extra_charges)
                    : 0) +
                  (props.printFood
                    ? parseFloat(props.invoiceDetails.food_price)
                    : 0) +
                  (props.printFood
                    ? parseFloat(props.invoiceDetails.food_tax_amount)
                    : 0) +
                  parseFloat(props.invoiceDetails.tax_amount)
              )}
            </p>
            <div className="footer-data">
              <div className="left-foot" style={{ paddingBottom: "5px 0" }}>
                <div className="invoice-footer">
                  {props.printRemarks && (
                    <p
                      style={{
                        fontWeight: "400",
                        margin: "0",
                      }}
                    >
                      Remarks: {props.invoiceDetails.remarks}
                    </p>
                  )}
                </div>
                <strong style={{ textAlign: "start" }}>
                  <pre style={{ margin: "0" }}>
                    {clientDetails.footer_contents}
                  </pre>
                </strong>
              </div>
              <div className="right-foot">
                <p>For {clientDetails.business_name}</p>
                {clientDetails.sign_image && (
                  <img
                    src={clientDetails.sign_image}
                    alt="Signature"
                    height={"80px"}
                    width={"140px"}
                  />
                )}
                <p>Authorised Signature</p>
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontWeight: "500",
                marginBottom: "2px",
              }}
            >
              !! Thank you for your stay !!
            </p>
          </div>

          {/* Print Button */}
        </div>
      </div>
      <br />
      <button className="print-btn" onClick={reactToPrintFn}>
        Print Invoice
      </button>
    </section>
  );
}

export default InvoicePage;
