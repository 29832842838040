import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../../styles/admins/rooms/add_rooms.css";

const AddRooms = (props) => {
  const location = useLocation();
  const [roomData, setRoomData] = useState({
    room_number: location.state !== null ? location.state.room_number : "",
    room_type: location.state !== null ? location.state.room_type : "",
    bed_type: location.state !== null ? location.state.bed_type : "",
    price_per_night: location.state !== null ? location.state.price : "",
    max_occupancy: location.state !== null ? location.state.max_occupancy : "",
    room_description:
      location.state !== null ? location.state.room_description : "",
    amenities: location.state !== null ? location.state.amenities : "",
    floor_number: location.state !== null ? location.state.floor_number : "",
    withOrWithout: "Without Tax",
  });

  const handleChange = (e) => {
    setRoomData({ ...roomData, [e.target.name]: e.target.value });
  };

  // Handle change in number input
  const handleNumberChange = (e) => {
    const value = e.target.value;

    if (value.length <= 7 && /^[0-9]*$/.test(value)) {
      setRoomData({ ...roomData, [e.target.name]: e.target.value });
    } else {
      props.handleToast({
        toastMsg: "Invalid Contact! Please enter a valid mobile number",
        toastColor: "#FF0000",
      });
      setRoomData({ ...roomData, [e.target.name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.handleLoading();

    if (location.state !== null) {
      roomData.room_id = location.state.room_id;
    }

    if (roomData.withOrWithout === "With Tax") {
      roomData.price_per_night = parseFloat(
        ((roomData.price_per_night * 100) / 112).toFixed(2)
      );
    }

    try {
      const response = await axios.post(
        location.state === null
          ? `https://api.dectwinhotel.com/api/${props.id}_rooms/add-rooms`
          : `https://api.dectwinhotel.com/api/${props.id}_rooms/update-rooms`,
        roomData
      );
      props.closeLoading();
      if (response.status === 200) {
        props.handleToast({
          toastMsg: "Room Added Successfully!!!",
          toastColor: "#4CAF50",
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        localStorage.removeItem("rooms");
        // Reset form
        setRoomData({
          room_number: "",
          room_type: "",
          bed_type: "",
          price_per_night: "",
          max_occupancy: "",
          room_description: "",
          amenities: "",
          floor_number: "",
        });
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (error) {
      props.closeLoading();
      props.handleToast({
        toastMsg: "Something went wrong! Please check data or internet.",
        toastColor: "#FF0000",
      });
    }
  };

  return (
    <section style={{ padding: "4rem 1rem" }}>
      <div className="add-room-container">
        <form className="add-room-form" onSubmit={handleSubmit}>
          <h2 className="form-title">
            {location.state ? "Edit Room Details" : "Add a Room"}
          </h2>

          <div className="form-group">
            <label>Room Number:</label>
            <input
              type="text"
              name="room_number"
              minLength={1}
              value={roomData.room_number}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Room Type:</label>
            <input
              type="text"
              name="room_type"
              minLength={5}
              value={roomData.room_type}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Bed Type:</label>
            <input
              type="text"
              name="bed_type"
              minLength={5}
              value={roomData.bed_type}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Price Per Night:</label>
            <div className="d-flex">
              <input
                type="text"
                maxLength={7}
                name="price_per_night"
                value={roomData.price_per_night}
                onChange={handleNumberChange}
                required
              />
              <select
                name="withOrWithout"
                value={roomData.withOrWithout}
                onChange={handleChange}
                required
              >
                <option value="Without Tax">Without Tax</option>
                <option value="With Tax">With Tax</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Max Occupancy:</label>
            <input
              type="text"
              name="max_occupancy"
              maxLength={2}
              value={roomData.max_occupancy}
              onChange={handleNumberChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Room Description:</label>
            <textarea
              name="room_description"
              value={roomData.room_description}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Amenities (comma separated):</label>
            <input
              type="text"
              name="amenities"
              value={roomData.amenities}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Floor Number:</label>
            <input
              type="text"
              maxLength={2}
              name="floor_number"
              value={roomData.floor_number}
              onChange={handleNumberChange}
            />
          </div>

          {/* <div className="form-group">
                        <label>View Type:</label>
                        <input
                            type="text"
                            name="view_type"
                            value={roomData.view_type}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>Room Size (sqm):</label>
                        <input
                            type="number"
                            name="room_size"
                            value={roomData.room_size}
                            onChange={handleChange}
                        />
                    </div> */}

          <button type="submit" className="submit-btn-room">
            {location.state ? "Edit Room" : "Add Room"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddRooms;
