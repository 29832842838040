import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../styles/admins/rooms/adv_book_history.css";
import AdvanceRoomCard from "../../generals/adv_room_card";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const buttonStyles = {
  backgroundColor: "#007bff",
  border: "none",
  color: "#fff",
  padding: "6px 16px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

const editButton = {
  backgroundColor: "#008800",
  border: "none",
  color: "#fff",
  padding: "6.5px 8px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "0.95rem",
  fontWeight: "600",
};

const AdvanceBookingHistory = (props) => {
  const [bookings, setBookings] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [showNormal, setShowNormal] = useState(false);
  const navigate = useNavigate();

  // Fetch booking details if room is booked in advance
  useEffect(() => {
    const storedRooms = localStorage.getItem("rooms");
    axios
      .get(
        `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/advance-history`
      )
      .then((response) => {
        setBookings(response.data);
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      });
    if (storedRooms) {
      // Use cached data if available
      setRooms(JSON.parse(storedRooms));
    } else {
      // Fetch products from the API
      axios
        .get(`https://api.dectwinhotel.com/api/${props.id}_rooms/rooms`)
        .then((response) => {
          // Store products in localStorage
          localStorage.setItem("rooms", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    }
  }, [props.id]);

  // Handle check-in creation for a specific booking
  const handleCancel = async (booking) => {
    props.handleLoading();
    await axios.put(
      `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/cancel-booking/${booking.advance_booking_id}`
    );
    navigate("/admin/dashboard");
    props.closeLoading();
  };

  const handleCheckIn = async (booking) => {
    props.handleLoading();
    try {
      booking.live_image = null;
      booking.live_image_file = null;
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${props.id}_bookings/checkin-room`,
        booking,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      props.closeLoading();
      if (response.status === 201) {
        props.handleToast({
          toastMsg: "Check In Successfully!!!",
          toastColor: "#4CAF50",
        });
        await axios.put(
          `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/success-booking/${booking.advance_booking_id}`
        );
        navigate("/admin/dashboard");
        localStorage.removeItem("rooms");
        // navigate("/admin/rooms");
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (err) {
      props.closeLoading();
    }
  };

  return (
    <section
      style={{
        backgroundColor: "#282c34",
        padding: "1rem",
        minHeight: "90vh",
      }}
    >
      <div className="advance-bookings-section">
        <h2>Advance Booking History</h2>
        <div className="d-flex justify-content-between mb-3">
          {showNormal ? (
            <div></div>
          ) : (
            <div className="date-picker-adv">
              Pick A Date : &nbsp;
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
          )}
          <button
            style={{ backgroundColor: "#0000ff" }}
            className="fetch-btn"
            onClick={() => setShowNormal(!showNormal)}
          >
            {showNormal ? "View Calender" : "Recent Bookings"}
          </button>
        </div>
        {showNormal && bookings.length > 0 && (
          <div className="table-responsive">
            <table className="reports-table">
              <thead>
                <tr>
                  <th>Booking ID</th>
                  <th>Guest Name</th>
                  <th>Room No</th>
                  <th>Check-in Date</th>
                  <th>Check-out Date</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((books) => (
                  <tr key={books.booking_id}>
                    <td>ADV{books.advance_booking_id}</td>
                    <td>{JSON.parse(books.guest_ids).name[0]}</td>
                    <td>{books.room_no}</td>
                    <td>{new Date(books.from_date).toLocaleString()}</td>
                    <td>{new Date(books.to_date).toLocaleString()}</td>
                    <td>₹{books.total_amount}</td>
                    <td>
                      <div className="d-flex justify-content-around align-items-center">
                        {books.booking_status === "pending" ? (
                          <>
                            <button
                              style={buttonStyles}
                              onClick={() => {
                                handleCheckIn(books);
                              }}
                            >
                              Checkin
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                              style={{
                                ...editButton,
                                backgroundColor: "#FF0000",
                              }}
                              onClick={() => {
                                handleCancel(books);
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;
                            </button>
                          </>
                        ) : (
                          <button
                            style={{
                              ...editButton,
                              backgroundColor:
                                books.booking_status === "cancelled"
                                  ? "#ff0000"
                                  : "#008800",
                              color: "white",
                            }}
                          >
                            {books.booking_status.toUpperCase()}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!showNormal && (
          <div className="advance-bookings-cards">
            {rooms.length > 0 ? (
              rooms.map((room) => (
                <AdvanceRoomCard
                  key={room.room_id}
                  id={props.id}
                  roomData={{ ...room, booking_id: bookings.length + 1 }}
                  bookingData={bookings.filter((elm) => {
                    return elm.room_id === room.room_id;
                  })}
                  handleCancel={handleCancel}
                  handleCheckIn={handleCheckIn}
                  today={startDate}
                />
              ))
            ) : (
              <div className="no-rooms-message">
                <i
                  style={{
                    fontSize: "6rem",
                    margin: "30px 10px",
                    color: "black",
                  }}
                  className="fa fa-bed"
                ></i>
                <p>No Advance Bookings done yet.</p>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default AdvanceBookingHistory;
