import React, { useState, useEffect } from "react";
import axios from "axios";
import RoomCard from "../../generals/room_card";

function AdminRooms({ handleToast, id }) {
  const [rooms, setRooms] = useState([]);

  window.addEventListener("beforeunload", () => {
    localStorage.removeItem("rooms");
  });

  useEffect(() => {
    // localStorage.removeItem('rooms');
    // Check if products are already in localStorage
    const storedRooms = localStorage.getItem("rooms");
    if (storedRooms) {
      // Use cached data if available
      setRooms(JSON.parse(storedRooms));
    } else {
      axios
        .get(`https://api.dectwinhotel.com/api/${id}_rooms/rooms`)
        .then((response) => {
          // Store products in localStorage
          localStorage.setItem("rooms", JSON.stringify(response.data));
          setRooms(response.data);
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            window.location.reload();
          }
          console.error("Error fetching products:", error);
        });
    }
  }, [id]);

  return (
    <section>
      <div className="rooms-data">
        {/* <div style={cardStyles} className="card-hover">
          <div>
            <img src={roomImg} alt="Card top" style={imgStyles} />
          </div>
          <div style={cardBodyStyles}>
            <Link to="/admin/book-room" state={roomData}>
              <h5 style={cardTitleStyles}>Book Multiple Rooms</h5>
            </Link>

            <p style={cardTextStyles}>Bed Type: {roomData.bed_type}</p>
            <p style={cardTextStyles}>Room Type: {roomData.room_type}</p>

            <div style={priceContainerStyles}>
              <span style={discountedPriceStyles}>
                Price: ₹{roomData.price}
              </span>
            </div>

            <div
              className="rooms-data"
              style={{ justifyContent: "space-between" }}
            >
              <Link to="/admin/book-room" state={roomData}>
                <button style={buttonStyles}>Check In</button>
              </Link>
              <Link to="/admin/add-room" state={roomData}>
                <button style={editButton}> Edit Room </button>
              </Link>
            </div>
          </div>
        </div> */}
        {rooms.length > 0 ? (
          rooms.map((room) => (
            <RoomCard
              key={room.room_id}
              roomData={room}
              handleToast={handleToast}
              id={id}
            />
          ))
        ) : (
          <div className="no-rooms-message">
            <i
              style={{ fontSize: "6rem", margin: "30px 10px" }}
              className="fa fa-bed"
            ></i>
            <p>No Rooms Added Yet.</p>
          </div>
        )}
      </div>
    </section>
  );
}

export default AdminRooms;
